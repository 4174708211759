import tippy, {Props} from "tippy.js"
import {ActionReturn} from "svelte/action"
import "tippy.js/dist/tippy.css"

export default (node: HTMLElement, params: Partial<Props>): ActionReturn<Props> => {
    params = params || <Props>{}
    params.allowHTML = true

    const custom = params.content
    const title = node.title
    const label = node.getAttribute("aria-label")
    let content = custom || title || label
    if (content) {
        content = `<div class="flex justify-center text-center items-center font-semibold">${ content }</div>`
    }

    // @ts-ignore
    if (params.appendTo === "self") {
        params.appendTo = node
    }

    if (!params.theme) {
        params.theme = "msd-tippy-theme"
    }

    // Support any of the Tippy props by forwarding all "params":
    // https://atomiks.github.io/tippyjs/v6/all-props/
    let tip = content ? tippy(node, {
        // @ts-ignore
        duration: 100,
        ...params,
        content
    }) : undefined

    return {
        update: (newParams: Partial<Props>) => {
            content = newParams.content ?? null

            // @ts-ignore
            if (newParams.appendTo === "self") {
                newParams.appendTo = node
            }

            if (!newParams.theme) {
                newParams.theme = "msd-tippy-theme"
            }

            if (content) {
                content = `<div class="flex justify-center text-center items-center font-semibold">${ content }</div>`
                if (tip === undefined) {

                    tip = tippy(node, {
                        // @ts-ignore
                        duration: 100,
                        ...params,
                        ...newParams,
                        content
                    })
                }

                tip.setProps({
                    // @ts-ignore
                    duration: 100,
                    ...newParams,
                    content
                })
            } else {
                tip?.destroy()
                tip = undefined
            }
        },

        destroy: () => tip?.destroy(),
    }
}
